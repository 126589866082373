<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>{{ !!qrId ? '編集' : '新規登録' }}</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="true">
                      <template v-slot:label>{{ useTicketFlag ? 'TICKETカテゴリーの選択' : 'QRカテゴリーの選択' }}</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-select">
                            <select
                              :class="{ 'form-control form-select-input': true, 'is-error': errors.categoryId || errors.categoryCurrencyId }"
                              name="categoryId"
                              v-model="form.categoryId"
                            >
                              <option value="" selected>選択してください</option>
                              <option
                                v-for="item in categories"
                                :key="item.id"
                                :value="item.id"
                              >{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>QRコード名称</template>
                      <template v-slot:labelNote>（20文字以内）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            v-trim
                            v-maxlength
                            maxlength="20"
                            class="form-control"
                            :class="{ 'is-error': errors.name }"
                            type="text"
                            name="name"
                            placeholder=""
                            v-model="form.name"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>発行元の選択</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div v-if="qrId || (isClientChild && issuerQrCodes.length)" class="row">
                            <div class="col-12">{{ form.issuerName }}</div>
                          </div>
                          <div v-else class="row">
                            <SearchSelect
                              ref="searchSelect"
                              singleLabel="name"
                              :isError="errors.issuer"
                              :options="issuerQrCodes"
                              :multiple="false"
                              :closeOnSelect="true"
                              :searchKeyMinLength="searchKeyLength"
                              :searchAction="searchActionIssuerQrCode"
                              :resetOptions="resetOptionIssuerQrCode"
                              :params="shopParams"
                              :searchField="searchField"
                              @change-selection="handleSelectedIssuer"
                            />
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>{{ useTicketFlag ? '発行TICKET' : '発行QR' }}</template>
                      <template v-slot:content>
                        <div v-if="!qrId" class="form-content-row">
                          <ul class="listGrid">
                            <li v-for="item in typeList" :key="item.value" class="listGrid-item">
                              <label class="form-radio form-radio-btn">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  name="type"
                                  :value="item.value"
                                  v-model="form.type"
                                />
                                <span :class="{ 'form-radio-label': true, 'is-error': useTicketFlag ? errors.type : errors.currencyType }">{{ item.label }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div v-else class="row">
                          <div class="col-12">{{ form.typeName }}</div>
                        </div>
                        <div
                          class="form-content-row mt-20"
                          v-if="!useTicketFlag"
                        >
                          <div class="form-group">
                            <div class="form-group-item">
                              <label v-if="!!hasAmount">{{ ticketQrDetail.amount }}</label>
                              <input v-else
                                class="form-control"
                                :class="{ 'is-error': errors.amount }"
                                type="tel"
                                v-number="'integer'"
                                name="amount"
                                placeholder=""
                                v-model="form.amount"
                              />
                            </div>
                            <div class="form-group-item">円</div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true" v-if="useTicketFlag">
                      <template v-slot:label>発行/消費するTICKETと口数/枚数を選択</template>
                      <template v-slot:content>
                        <div class="form-row" v-if="isFormTicketExisted">
                          <div v-for="(item, index) in form.ticket" :key="item.id" class="form-content-row">
                            <div class="form-group row">
                              <div class="form-group-item col-8 form-group-checked">
                                <label class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="isChecked"
                                    v-model="form.ticket[index].isChecked"
                                    :disabled="!!qrId"
                                    @change="handleSelectedItem(item, index)"
                                  />
                                  <span :class="{ 'form-check-label form-check-card': true, 'is-error-checkbox': errors.isChecked || errors[`ticket.${index}.id`] }">
                                    {{ item.name }}<br>{{ item.amountBreakdown | comma }}円ｘ{{ item.unitBreakdown }}枚
                                  </span>
                                </label>
                              </div>
                              <div class="form-group-item col-4 form-group-selected">
                                <div class="form-content-row">
                                  <div class="form-select">
                                    <select
                                      :class="{ 'form-control form-select-input': true, 'is-error': errors[`quantity${index}`] || errors[`ticket.${index}.quantity`] }"
                                      :name="`quantity${index}`"
                                      v-model="form.ticket[index].quantity"
                                      :disabled="!form.ticket[index].isChecked || !!qrId"
                                    >
                                      <option value="">選択してください</option>
                                      <option v-for="data in convertNumberToArrayWithKey(999)" :key="data.key">{{ data.value }}</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p v-else>一致するデータがありません。</p>
                      </template>
                    </FormRow>
                    <FormRow :required="true" v-else>
                      <template v-slot:label>有効期限</template>
                      <template v-slot:content>
                        <ul class="listGroup">
                          <li v-for="(item, index) in expirationDateTypes" :key="index" class="listGroup-item">
                            <label class="form-radio">
                              <input
                                class="form-radio-input"
                                type="radio"
                                name="setExpiration"
                                v-model="form.setExpiration"
                                :value="item.value"
                                @change="form.expirationMonths = null"
                              />
                              <span :class="{ 'form-radio-label': true, 'is-error': errors.setExpiration }">{{ item.label }}</span>
                            </label>
                            <transition name="fade" v-if="form.setExpiration === settingValue && item.value == settingValue">
                              <div class="form-content-row">
                                <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <div class="form-group-item">
                                        <div class="form-select">
                                          <select class="form-control form-select-input" v-model="form.expirationMonths">
                                            <option :value="null">選択してください</option>
                                            <option v-for="month in expirationMonthCount" :key="month" :value="month">{{ month }}</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="form-group-item">ヵ月</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </transition>
                          </li>
                        </ul>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>利用制限</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGroup">
                            <li class="listGroup-item">
                              <label class="form-radio">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  :value="true"
                                  name="useLimitFlag"
                                  v-model="form.useLimitFlag"
                                />
                                <span class="form-radio-label">あり</span>
                              </label>
                              <transition name="fade">
                                <ul class="listGroup" v-show="form.useLimitFlag">
                                  <li>
                                    <p class="form-label">ワンタイムトークン</p>
                                  </li>
                                  <li class="listGroup-item listGroup-qr">
                                    <label class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="useOneTimeToken"
                                        v-model="form.useOneTimeToken"
                                        :disabled="canEditOneTimeToken"
                                      />
                                      <span :class="{ 'form-check-label': true }">ワンタイムトークンの利用</span>
                                    </label>
                                  </li>
                                  <li>
                                    <p class="form-label">QR期間設定</p>
                                  </li>
                                  <li class="listGroup-item listGroup-qr">
                                    <label class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="expiredTime"
                                        v-model="form.expiredTime"
                                        :value="true"
                                      />
                                      <span :class="{ 'form-check-label': true, 'is-error': errors.expiredTime }">利用可能の期間設定</span>
                                    </label>
                                    <transition name="fade">
                                      <div class="form-content-row listGroup-item-child" v-show="hasExpiredTime">
                                        <DateRange
                                          field="useTicketDate"
                                          :value="form.useTicketDate"
                                          :errorField="errors.useTicketDate"
                                          @on-change="onChangeDateRange"
                                        />
                                      </div>
                                    </transition>
                                  </li>
                                  <li>
                                    <p class="form-label">QR顧客利用制限</p>
                                  </li>
                                  <li class="listGroup-item listGroup-qr">
                                    <div v-for="item in limitTypes" :key="item.value" class="listGroup-item listGroup-point">
                                      <label class="form-radio">
                                        <input
                                          class="form-radio-input"
                                          type="radio"
                                          name="limitType"
                                          v-model="form.limitType"
                                          :disabled="!!isNumberTimesLimit"
                                          :value="item.value"
                                        />
                                        <span :class="{ 'form-radio-label': true, 'is-error': errors.limitType }">{{ item.label }}</span>
                                      </label>
                                      <transition name="fade">
                                        <div
                                          v-show="item.value === limitTypes.timesLimit.value && isTimesLimit"
                                          class="form-content-row listGroup-item-child"
                                        >
                                          <div class="form-group">
                                            <div class="form-group-item">
                                              <label v-if="!!isNumberTimesLimit">{{ ticketQrDetail.numberTimesLimit }}</label>
                                              <input v-else
                                                class="form-control"
                                                :class="{ 'is-error': errors.numberTimesLimit }"
                                                type="tel"
                                                v-number="'integer'"
                                                name="numberTimesLimit"
                                                placeholder=""
                                                v-maxlength
                                                maxlength="3"
                                                v-model="form.numberTimesLimit"
                                              />
                                            </div>
                                            <div class="form-group-item">回/同一顧客</div>
                                          </div>
                                        </div>
                                      </transition>
                                    </div>
                                  </li>
                                  <li>
                                    <p class="form-label">QR利用制限</p>
                                  </li>
                                  <li class="listGroup-item listGroup-qr">
                                    <label class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="useQuantityFlag"
                                        :disabled="!!isUseQuantityFlag"
                                        v-model="form.useQuantityFlag"
                                      />
                                      <span :class="{ 'form-check-label': true }">利用可能回数の設定</span>
                                    </label>
                                    <transition name="fade">
                                      <div
                                        v-show="form.useQuantityFlag"
                                        class="form-content-row listGroup-item-child"
                                      >
                                        <div class="form-group">
                                          <div class="form-group-item">
                                            <label v-if="!!isUseQuantityFlag">{{ ticketQrDetail.totalLimit }}</label>
                                            <input v-else
                                              class="form-control"
                                              :class="{ 'is-error': errors.totalLimit }"
                                              type="tel"
                                              v-number="'integer'"
                                              v-maxlength
                                              maxlength="10"
                                              name="totalLimit"
                                              v-model="form.totalLimit"
                                            />
                                          </div>
                                          <div class="form-group-item">回</div>
                                        </div>
                                      </div>
                                    </transition>
                                  </li>
                                </ul>
                              </transition>
                            </li>
                            <li class="listGroup-item">
                              <label class="form-radio">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  :value="false"
                                  name="useLimitFlag"
                                  v-model="form.useLimitFlag"
                                  :disabled="isUseLimitFlag"
                                />
                                <span class="form-radio-label">なし</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'EventCurrencyQr' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :disabled="!isFormTicketExisted" :handle-submit="handleSubmit" :button-text="buttonText" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength, between, requiredIf } from 'vuelidate/lib/validators';
//mixins
import nav from '@/mixins/nav/currency';
import error from '@/mixins/plugin/error';
//components
import FormRow from '@/components/FormRow.vue';
import DateRange from '@/components/DateRange.vue';
import SearchSelect from '@/components/SearchSelect.vue';
//constants
import { EventConstants } from '@/constants/event';
//helpers
import { convertNumberToArrayWithKey } from '@/helpers/formatData';

export default {
  data: function() {
    return {
      pageName: '通貨管理',
      convertNumberToArrayWithKey,
      form: {
        useTicketDate: {
          start: '',
          end: '',
        },
      },
      limitTypes: {
        timesLimit: { label: "1QR当り利用制限", value: "timesLimit" },
        oneTimePerDay: { label: "1顧客1日1回のみ", value: "oneTimePerDay" },
      },
      prefixs: ['useTicketDate'],
      searchField: 'issuer',
      storeModule: 'currency',
      resetOptionIssuerQrCode: 'currency/RESET_ISSUER_QR_CODE_LIST',
      settingValue: EventConstants.expirationDateType.setting.value,
      expirationMonthCount: EventConstants.EXPIRATION_MONTH_COUNT,
    }
  },
  mixins: [nav, error],
  components: {
    FormRow,
    DateRange,
    SearchSelect,
  },
  validations() {
    let form = {
      categoryId: {},
      categoryCurrencyId: {},
      name: { required, maxLength: maxLength(20) },
      issuer: { selectRequired: required },
      type: {},
      currencyType: {},
      amount: {},
      isChecked: {},
      quantity: {},
      useTicketDate: {},
      numberTimesLimit: {},
      totalLimit: {},
    }
    if (this.useTicketFlag) {
      form.categoryId = { selectRequired: required };
      form.type = { selectRequired: required };
    } else {
      if (!this.form.categoryId) form.categoryCurrencyId = { selectRequired: required };
      if (this.form.type === null) {
        form.currencyType = { selectRequired: required };
      } else {
        form.amount = { required, between: between(1, 999999) };
      }
    }
    if (this.hasExpiredTime) {
      form.useTicketDate = {
        start: { selectRequired: required },
        end: { selectRequired: required },
      }
    }
    if (this.isTimesLimit) {
      form.numberTimesLimit = { required, between: between(1, 999) };
      if (!this.ticketQrDetail.numberTimesLimit && this.form?.useQuantityFlag && this.form.totalLimit && (Number(this.form.numberTimesLimit) > Number(this.form.totalLimit))) {
        form.numberTimesLimit = { numberLimitWithMaxValue: () => false };
      }
    }
    if (this.form?.useQuantityFlag && this.form?.useLimitFlag) {
      form.totalLimit = { required, between: between(1, 1000000000) };
      if (!!this.ticketQrDetail.numberTimesLimit && this.form.totalLimit && (Number(this.form.numberTimesLimit) > Number(this.form.totalLimit))) {
        form.totalLimit = { totalLimitWithMaxValue: () => false };
      }
    }
    if (this.useTicketFlag) {
      form = {
        ...form,
        isChecked: {
          selectRequired: requiredIf(function(eventItem) {
            const hasCheckedItem = eventItem?.ticket?.some(item => item.isChecked);
            return !hasCheckedItem;
          })
        },
        quantity: {
          selectRequired: requiredIf(function(eventItem) {
            const hasCheckedItem = eventItem?.ticket?.some(item => item.isChecked);
            if (!hasCheckedItem) return;
            const filteredInvalidIndex = eventItem?.ticket?.reduce((array, item, index) => {
              if (item.isChecked && !item.quantity) {
                array.push(index);
              }
              return array;
            }, []);
            if (filteredInvalidIndex?.length) {
              filteredInvalidIndex.map(e => this.errors[`quantity${e}`] = true);
              return true;
            }
            return false;
          })
        },
      }
    }
    return { form };
  },
  computed: {
    ...mapGetters({
      hasShopRole: 'auth/hasShopRole',
      shopPointList: 'parentPoint/shopPointList',
      categories: 'currency/categoriesList',
      qrCodeTickets: 'currency/qrCodeTickets',
      issuerQrCodes: 'currency/issuerQrCodes',
      ticketQrDetail: 'currency/ticketQrDetail',
      isClientChild: 'auth/isClientChild'
    }),
    shopParams() {
      return {
        validFlag: true,
        openFlag: true,
        directory: this.directory,
      }
    },
    directory() {
      return this.$route.params?.directory;
    },
    isTimesLimit() {
      return this.form.useLimitFlag && this.form.limitType === this.limitTypes.timesLimit.value;
    },
    isTimesPerDay() {
      return this.form.useLimitFlag && this.form.limitType === this.limitTypes.oneTimePerDay.value;
    },
    hasExpiredTime() {
      return this.form.useLimitFlag && !!this.form?.expiredTime;
    },
    qrId() {
      return this.$route.params.id;
    },
    isUseQuantityFlag() {
      return !!this.qrId && !!this.ticketQrDetail?.useQuantityFlag && !!this.ticketQrDetail?.totalLimit;
    },
    isNumberTimesLimit() {
      return !!this.qrId && !!this.ticketQrDetail?.numberTimesLimit;
    },
    hasAmount() {
      return !!this.qrId && !!this.ticketQrDetail.amount;
    },
    buttonText() {
      return this.qrId ? '編集する' : '登録する';
    },
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
    isUseLimitFlag() {
      return this.qrId && !!this.ticketQrDetail?.useLimitFlag;
    },
    canEditOneTimeToken() {
      return this.qrId && !this.ticketQrDetail?.canEditOneTimeToken;
    },
    isFormTicketExisted() {
      return !this.useTicketFlag || this.form.ticket?.length > 0;
    },
    convertTicketForm() {
      return this.form.ticket.filter(e => {
        return {
          id: e?.id,
          quantity: e?.quantity
        }
      });
    },
    expirationDateTypes() {
      return Object.values(EventConstants.expirationDateType);
    },
    searchActionIssuerQrCode() {
      return this.useTicketFlag ? 'currency/getIssuerQrCodeList' : 'currency/getCurrencyIssuerQrCodeList';
    },
    typeList() {
      const data = {
        add: { label: "付与（加算）", value: 0 },
        use: { label: "消費（減算）", value: 1 },
      };
      if (this.isClientChild) delete data.use;
      return data;
    },
  },
  mounted() {
    const loading = this.$loading.show();
    this.initForm();
    Promise.all([
      this.getCategories(),
      this.getQrCodeTickets(),
      this.getTicketQrDetail(),
      this.getClientChildIssuer(),
    ]).finally(() => {
      if (!this.qrId) {
        this.form.ticket = this.qrCodeTickets?.map((e) => ({ ...e, quantity: '', isChecked: false })) || [];
      } else {
        this.form.ticket = this.ticketQrDetail?.ticket?.map((e) => ({ ...e, isChecked: !!e.quantity })) || [];
      }
      this.$loading.clear(loading);
    })
  },
  watch: {
    'form.limitType'() {
      if (this.isTimesPerDay) {
        this.form.numberTimesLimit = null;
        this.errors.numberTimesLimit = false;
      }
    },
    'form.issuer'(val) {
      if (!val && !this.qrId) {
        this.getClientChildIssuer();
      }
    }
  },
  methods: {
    initForm() {
      this.form = {
        issuer: null,
        categoryId: '',
        name: '',
        type: 0,
        useLimitFlag: false,
        useOneTimeToken: false,
        limitType: this.limitTypes.timesLimit.value,
        numberTimesLimit: null,
        totalLimit: null,
        expiredTime: false,
        useTicketDate: {
          start: '',
          end: '',
        },
        setExpiration: 0,
        expirationMonths: null,
        ticket: [],
      }
    },
    async getTicketQrDetail() {
      const action = this.useTicketFlag ? 'getTicketQRDetail' : 'getCurrencyQRDetail';
      if (this.qrId) {
        const ticketQrDetail = await this.$store.dispatch(`${this.storeModule}/${action}`, {
          id: this.qrId,
          subdomain: this.subdomain
        });
        const { useEndDate, useStartDate, ...form} = ticketQrDetail;
        this.form = {
          ...form,
          issuer: ticketQrDetail?.issuer?.value || 0,
          limitType: ticketQrDetail.oneTimePerDay ? this.limitTypes.oneTimePerDay.value : this.limitTypes.timesLimit.value,
          typeName: Object.values(this.typeList).find(item => item.value === ticketQrDetail?.type)?.label,
          useTicketDate: {
            ...this.form.useTicketDate,
            start: useStartDate,
            end: useEndDate,
          },
          expiredTime: this.ticketQrDetail?.useLimitFlag && !!this.ticketQrDetail?.useStartDate && !!this.ticketQrDetail?.useEndDate,
          issuerName: ticketQrDetail?.issuer?.name || '事務局',
          useLimitFlag: !!this.ticketQrDetail?.useLimitFlag,
        }
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'ticketQR');
        return false;
      } else {
        let { useTicketDate, numberTimesLimit, ...payload } = this.form;
        const ticketPath = this.qrId ? 'updateQrTicket' : 'createQrTicket';
        const currencyPath = this.qrId ? 'updateCurrencyQr' : 'createCurrencyQr';
        const actionPath = this.useTicketFlag ? ticketPath : currencyPath;
        const result = await this.$store.dispatch(`${this.storeModule}/${actionPath}`, {
          ...payload,
          subdomain: this.subdomain,
          numberTimesLimit: this.isTimesLimit ? numberTimesLimit : 0,
          oneTimePerDay: this.isTimesPerDay ? true : false,
          expiredTime: this.hasExpiredTime ? true : false,
          useStartDate: this.hasExpiredTime ? useTicketDate.start : '',
          useEndDate: this.hasExpiredTime ? useTicketDate.end : '',
          ticket: this.useTicketFlag ? this.convertTicketForm : null,
        });
        if (result) {
          const actionName = this.qrId ? 'updated' : 'created';
          await this.$router.push({ name: 'EventCurrencyQr' });
          this.$message[actionName]('pointQR');
        }
      }
    },
    async getClientChildIssuer() {
      if (this.isClientChild) {
        await this.$store.dispatch(this.searchActionIssuerQrCode, {
          ...this.shopParams,
          limit: 9999,
        });
        this.form.issuer = this.issuerQrCodes[0]?.value;
        this.form.issuerName = this.issuerQrCodes[0]?.name;
      }
    },
    onChangeDateRange(field, type, val) {
      this.form[field] = {
        ...this.form[field],
        [type]: val,
      }
    },
    handleSelectedIssuer(item) {
      this.form.issuer = item?.value;
    },
    handleSelectedItem(item, index) {
      if (!item.isChecked) {
        this.form.ticket[index].quantity = '';
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.listGroup {
  padding-left: 30px;
  &-item-child {
    margin-left: 15px;
  }
}

.listGroup-qr {
  padding: 16px 0;
  min-width: 500px;
  margin-left: 20px;
  &:not(:last-child) {
    border: 0 !important;
  }
}

.listGroup-item {
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.listGroup-item .form-radio,
.listGroup-item .form-check {
  display: inline-flex;
  margin-bottom: 15px;
}

.form-group-item input {
  width: 80px;
}

.form-check-card {
  gap: 15px;
  font-weight: bold;
}

.form-group-checked {
  flex: 0 0 auto;
  width: 60%;
}

.form-group-selected {
  flex: 0 0 auto;
  width: 27%;
}
</style>
